import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "design-systems-coalition-nyc-meetup"
    }}>{`Design Systems Coalition NYC Meetup`}</h1>
    <p>{`This repository is for anything related to organizing the `}<a parentName="p" {...{
        "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/"
      }}>{`Design Systems Coalition meetup in NYC`}</a>{`. Organizers and members can use this to share and discuss topic ideas, speaker suggestions, locations for hosting, and ideas for the format of the meetup etc.`}</p>
    <h2 {...{
      "id": "how-to-get-involved-with-our-meetup"
    }}>{`How to get involved with our meetup`}</h2>
    <p>{`We hope to host meetups every 6 to 8 weeks, if you'd like to get invovled, here's how:`}</p>
    <h3 {...{
      "id": "offer-to-host-a-meetup"
    }}>{`Offer to host a meetup`}</h3>
    <p>{`We hope to host meetups regularly and are looking for companies with spaces that can accommodate 60—100 people. Read the `}<a parentName="p" {...{
        "href": "/hosting-guidelines"
      }}>{`hosting guidelines`}</a>{` and if you'd like to host one of our meetups, `}<a parentName="p" {...{
        "href": "https://github.com/design-systems-coalition-NYC/meetup/issues/new?milestone=Meetup+venues+and+hosts"
      }}>{`open a new issue`}</a>{`.`}</p>
    <h3 {...{
      "id": "suggest-speakers-or-offer-to-speak-yourself"
    }}>{`Suggest speakers, or offer to speak yourself`}</h3>
    <p>{`We want to hear from a diverse range of experiences. We welcome and will support first-time speakers, and people still new to design systems. If you think you have something interesting to share, or there are people or teams you'd like to hear from, `}<a parentName="p" {...{
        "href": "https://github.com/design-systems-coalition-NYC/meetup/issues/new?milestone=Speaker+suggestions"
      }}>{`open an issue`}</a>{`. Read our `}<a parentName="p" {...{
        "href": "/speaker-guidelines"
      }}>{`speaker guidelines`}</a>{` for more details on what we're looking for and what to expect as a speaker.`}</p>
    <h3 {...{
      "id": "suggest-topic-ideas"
    }}>{`Suggest topic ideas`}</h3>
    <p>{`At each meetup we have 3 speakers talk on a single topic related to design systems. If there's a topic you'd like to see covered in a future meetup please suggest your idea by `}<a parentName="p" {...{
        "href": "https://github.com/design-systems-coalition-NYC/meetup/issues/new?milestone=Topic+ideas"
      }}>{`opening a new issue`}</a>{`.`}</p>
    <h2 {...{
      "id": "code-of-conduct"
    }}>{`Code of Conduct`}</h2>
    <p>{`We invite all those who participate in Design Systems Coalition NYC to help us create safe and positive experiences for everyone. Our `}<a parentName="p" {...{
        "href": "http://designsystems.nyc/meetup/code-of-conduct"
      }}>{`code of conduct`}</a>{` outlines our expectations for all those who participate in our community, as well as the consequences for unacceptable behavior.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      